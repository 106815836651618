@import "./breakpoints";

@font-face {
    font-family: "ABCDiatype";
    src: url("../../public/fonts/ABCDiatype-Regular.otf") format("opentype");
    font-weight: normal;
}

@font-face {
    font-family: "ABCDiatype";
    src: url("../../public/fonts/ABCDiatype-Bold.otf") format("opentype");
    font-weight: bold;
}

body {
    color: $color_black;

    font-family: "ABCDiatype";

    font-size: 10px;
    line-height: 1.25;

    @media screen and (min-width: $break_md) {
        font-size: 12px;
    }
}

*::selection {
    background-color: $color_grey;
}

h1 {
    font-weight: bold;
    text-transform: uppercase;
}

a {
    color: inherit;
    text-decoration: none;

    &:hover {
        color: $color_grey;
    }

    &[target="_blank"] {
        &::after {
            content: " ↗";
        }
    }
}

sup {
    vertical-align: super;
    font-size: smaller;
}

.richtext {
    & > * {
        display: inline-block;
        margin-bottom: 0;

        &::after {
            content: "|";
            margin: 0.5rem 0;

            @media screen and (min-width: $break_md) {
                margin: 0 0.5rem;
            }
        }

        &:last-child::after {
            content: "";
            margin: 0;
        }
    }
}

.no-break {
    white-space: nowrap;
}
