@import "../../styles/breakpoints";

.wrapper {
  display: flex;
  gap: 5px;
}

.fixedWidth {
  display: inline-block;
  text-align: center;

  $padding: 0.025em;

  @media screen and (max-width: $break_md) {
    padding: $padding 0;
  }

  @media screen and (min-width: $break_md) {
    padding: 0 $padding;
  }
}
