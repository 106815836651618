@import "./color";
@import "./sizes";
@import "./util";

body {
  background-color: $color_white;
}

.flex {
  display: flex;
}

.gap {
  @include generateUtilClasses(gap, $sizes);
}
