// Generates ui-classes from a css-property and map of $names and $values.
// $map = ("a": b, "c": d);
// Usage: .gap { @generateUtilClasses(gap, ("s": 10px, "m": 20px)) }
@mixin generateUtilClasses($property, $map) {
  @each $name, $value in $map {
    &-#{$name} {
      #{$property}: $value;
    }
  }
}
