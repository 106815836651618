@import "./styles/sizes";
@import "./styles/breakpoints";

.main {
  display: flex;
  flex-direction: row;

  padding: $size_s;

  box-sizing: border-box;

  width: 100%;

  @media screen and (max-width: $break_md) {
    padding-right: calc(50vw - $size_s);

    writing-mode: vertical-rl;
    text-orientation: mixed;

    height: max-content;
  }

  @media screen and (min-width: $break_md) {
    padding-top: calc(50vh - $size_m);

    writing-mode: horizontal-tb;
    height: 100vh;
  }
}

.footer {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  // Add distance
  & > * {
    &:first-child {
      @media screen and (max-width: $break_md) {
        margin-bottom: $size_m; // When rotated
      }

      @media screen and (min-width: $break_md) {
        margin-right: $size_m;
      }
    }
  }
}

.grid {
  display: grid;
  column-gap: $size_s;

  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr 1fr;

  @media screen and (min-width: $break_md) {
    grid-template-columns: repeat(6, 1fr);
    column-gap: $size_m;
  }
}

.col {
  &_1 {
    grid-column: 1 / 3;

    @media screen and (min-width: $break_md) {
      grid-column: 1 / 2;
    }
  }

  &_2 {
    grid-column: 3 / -1;

    @media screen and (min-width: $break_md) {
      grid-column: 2 / -1;
    }
  }
}
